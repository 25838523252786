import { Router }   from '@reach/router'
import React        from 'react'
import { NotFound } from '../components'
import Image        from '../templates/image-template'
import Stream       from '../templates/stream-template'

const App = () => (
  <Router basepath='/app'>
    <Image path='/image/:year/:month/:index' />
    <Stream path='/stream' />
    <NotFound default />
  </Router>
)

export default App
