import styled                                 from '@emotion/styled'
import Grid                                   from '@mui/material/Grid'
import React, { useEffect, useRef, useState } from 'react'
import { Head }                               from '../components'
import { YearsProvider }                      from '../context'
import { Header, IvsPlayer }                  from '../features'
import { useIdToken }                         from '../hooks'
import { getStreamUrl }                       from '../services/graphql-utils'
import { useAuth0 }                           from '../services/react-auth0-spa'

const CHANNEL_ID = 'f9de1b69-83e7-4ad2-930f-3e844e2aa77a'

const PlayerGrid = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr;
  padding: 2vh 2vw 2vh 2vw;
  place-items: center;

  & video {
    width: 90vw;
    height: auto;
    aspect-ratio: 16 / 9;
  }

  @media only screen and (min-width: 900px) {
    & video {
      width: 70vw;
      height: auto;
    }
  }
`

export default ({ location: { state } }) => {
  const [ streamUrl, setStreamUrl ] = useState(null)
  const [ deviceOrientation, setDeviceOrientation ] = useState(0)
  const { getIdTokenClaims, getTokenSilently } = useAuth0()
  const [ idToken, setIdToken ] = useIdToken(null)
  const playerRef = useRef(null)

  window.addEventListener('orientationchange', event => setDeviceOrientation(event.target.screen.orientation.angle))

  const { years } = state

  useEffect(() => {
    if (!idToken) {
      return
    }

    const stream = async () => {
      try {
        const {
          stream: { url },
        } = await getStreamUrl({ token: idToken, channelID: CHANNEL_ID })

        setStreamUrl(url)
      } catch (error) {
        await getTokenSilently()
        const claims = await getIdTokenClaims()
        setIdToken(claims.__raw)
      }
    }

    stream()
  }, [ getIdTokenClaims, getTokenSilently, idToken, setIdToken ])

  return (
    streamUrl && (
      <YearsProvider value={{ years }}>
        <Head title='Live Stream' />
        <main>
          {deviceOrientation === 0 && <Header />}
          <PlayerGrid>
            <IvsPlayer playerRef={playerRef} url={streamUrl} />
          </PlayerGrid>
        </main>
      </YearsProvider>
    )
  )
}
