import { navigate }                     from 'gatsby'
import { useSwipeable }                 from 'react-swipeable'
import React, { useEffect }             from 'react'
import { Head }                         from '../components'
import { LinksProvider, YearsProvider } from '../context'
import { imageNavigationLinks }         from '../services/media-utils'

import { ImageContent }                 from './image-content'

const ImageTemplate = ({ year, month, index, location: { state } }) => {
  const swipedLeft =
    (_state, { next }) =>
      () => {
        if (next.url) {
          navigate(next.url, { state: { ..._state } })
        }
      }

  const swipedRight =
    (_state, { prev }) =>
      () => {
        if (prev.url) {
          navigate(prev.url, { state: { ..._state } })
        }
      }

  const navigateToMonth = () =>
    navigate(links.up.url, { state: { years, images } })

  // handle hacking of image URLs by redirecting to the associated month page
  useEffect(() => {
    if (state === null) {
      return window && window.location.replace(`/media/${year}/${month}`)
    }
  })

  if (state === null) {
    return null
  }

  const { images, years } = state
  const imageIndex = parseInt(index, 10)
  const links = imageNavigationLinks({
    imageIndex,
    year,
    month,
    total: images.length,
    images,
    years,
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const swipeHandlers = useSwipeable({
    onSwipedLeft: swipedLeft(state, links),
    onSwipedRight: swipedRight(state, links),
  })

  return (
    <YearsProvider value={{ years }}>
      <Head title={`${year}-${month} | ${imageIndex}`} />
      <LinksProvider value={{ links, navigate }}>
        <main {...swipeHandlers}>
          <ImageContent
            image={images[imageIndex]}
            imageIndex={imageIndex}
            navigateToMonth={navigateToMonth}
            total={images.length}
          />
        </main>
      </LinksProvider>
    </YearsProvider>
  )
}

export default ImageTemplate
