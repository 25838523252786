import Grid                 from '@mui/material/Grid'
import useMediaQuery        from '@mui/material/useMediaQuery'
import React, { useMemo }   from 'react'
import { StyledImage }      from '../components'
import { Header, MetaData } from '../features'

const ImageContent = ({ image, imageIndex, navigateToMonth, total }) => {
  const metadataRight = useMediaQuery('(min-width:1401px)')
  const metadataBottom = useMediaQuery('(max-width:1400px)')

  const { original, small, medium } = image
  const metadataCard = (
    <MetaData
      image={{ ...image, imageIndex, total }}
      navigateToMonth={navigateToMonth}
    />
  )

  const handleImageError = err => {
    throw new Error(`Problem retrieving image: ${err.target.src}`)
  }

  const smallUrl = useMemo(() => small.url, [ small.url ])
  const mediumUrl = useMemo(() => medium.url, [ medium.url ])
  const originalUrl = useMemo(() => original.url, [ original.url ])

  const progImage = (
    <StyledImage
      className='lazyload'
      alt={medium.key}
      data-sizes='auto'
      src={smallUrl}
      data-srcset={`${smallUrl} 320w, ${mediumUrl} 800w, ${originalUrl} 2000w`}
      onError={handleImageError}
    />
  )

  const metaDataRightGrid = (
    <Grid
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        marginRight: 1,
      }}
    >
      <Grid sx={{ gridColumn: 'span 9' }}>{progImage}</Grid>
      <Grid sx={{ gridColumn: 'span 3' }}>{metadataCard}</Grid>
    </Grid>
  )

  const metaDataBottomGrid = (
    <Grid
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        gridAutoFlow: 'column',
        gap: 2,
      }}
    >
      <Grid item>{progImage}</Grid>
      <Grid item>{metadataCard}</Grid>
    </Grid>
  )

  return (
    <>
      <Header />
      {metadataRight && metaDataRightGrid}
      {metadataBottom && metaDataBottomGrid}
    </>
  )
}

export { ImageContent }
